<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="AddRecordPrompt" persistent>
        <app-member-create
          :AddRecordPrompt="AddRecordPrompt"
          @hideDialog="hideAddRecordPrompt"
          v-if="AddRecordPrompt"
        ></app-member-create>
      </v-dialog>

      <v-dialog v-model="EditRecordPrompt" persistent>
        <app-member-edit
          PageTitle="Edit City"
          :EditRecordPrompt="EditRecordPrompt"
          :MemberId="recordToEdit"
          @hideDialog="hideEditRecordPrompt"
          v-if="EditRecordPrompt"
        ></app-member-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              @click.prevent="AddRecordPrompt = true"
              color="#8950FC"
              elevation="30"
              shaped
              tile
              large
              class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
            >Add Member</v-btn>
          </v-col>
        </v-row>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Zone
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> LOM
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import AppMemberCreate from "@/view/pages/erp/app-members/AppMemberCreate.vue";
import AppMemberEdit from "@/view/pages/erp/app-members/AppMemberEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { AppMemberCreate, AppMemberEdit },
  data() {
    return {
      LoadingFlag: false,
      SearchFlag: false,
      uploadPercentage: 0,
      AddRecordPrompt: false,
      EditRecordPrompt: false,

      selected: {},
      selectedData: {},

      valid1: true,
      OverlayLoadingFlag: false,

      JciYearCode: "",

      ZoneCodeRules: [v => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptionsLoading: false,
      ZoneCodeOptions: [],

      LomCodeRules: [v => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptionsLoading: false,
      LomCodeOptions: [],

      recordToEdit: "",

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function() {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "wm_members",
        Action: "list"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/wm/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      this.LomCodeOptionsLoading = true;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/wm/lom/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          ZoneCode: ZoneCode,
          AllLom: 1
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message
        };
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var ZoneCode = this.ZoneCode;
      var LomCode = this.LomCode;
      console.log("ZoneCode=" + ZoneCode + ", LomCode=" + LomCode);

      if (validate1) {
        this.LoadingFlag = true;
        this.SearchFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/wm/team/members/lists";
        var upload = {
          UserInterface: 1,
          ZoneCode: ZoneCode,
          LomCode: LomCode
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });
            var TableContent = response.data.table_content;

            var options = TableContent.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = TableContent.TableHeader;
              thisIns.tableData1 = records;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.toast("error", message);
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.AddRecordPrompt = false;
      this.searchForm();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.recordToEdit = tr.MemberId;
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one city to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.EditRecordPrompt = false;
      this.searchForm();
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> City Name <b> " + tr.CityName + "</b>";
        htmlTxt += "<br/> District Name <b> " + tr.DistrictName + "</b>";
        htmlTxt += "<br/> State Name <b> " + tr.StateName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6"
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr.CityId);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(CityId) {
      console.log("deleteData is called");

      console.log("CityId=" + CityId);

      if (CityId > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/city/destroy";
        var upload = {
          UserInterface: 1,
          City: CityId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Event Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>