<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="getCroppedImage"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Zone
                    </h6>
                  </label>
                  <v-autocomplete
                    @change="getLomCodeOptions"
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    label="Zone"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> LOM
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="LomCodeOptions"
                    :rules="LomCodeRules"
                    v-model="LomCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Member Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="MemberName"
                    :rules="MemberNameRules"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      Mobile Number
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="MobileNo"
                    :rules="MobileNoRules"
                    :counter="10"
                    v-mask="'##########'"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      Email Id
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    type="email"
                    v-model="EmailId"
                    :rules="EmailIdRules"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      Address
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-textarea v-model="Address" rows="3" value outlined solo></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      City
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field v-model="CityName" :rules="CityNameRules" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" sm="4" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-3">Member Image</h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Upload member image"
                    prepend-icon="mdi-card-bulleted-settings"
                    append-outer-icon="mdi-delete"
                    v-model="uploadedImages"
                    @change="addImage(1)"
                    @click:append-outer="deleteImage(1)"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="4" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-3">Actions</h6>
                  <div class="actions" v-if="SelectedImage.ImageUrl != null">
                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(true, false)">
                        <v-icon>fa fa-caret-right</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(false, true)">
                        <v-icon>fa fa-caret-up</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(90)">
                        <v-icon>fa fa-redo</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(-90)">
                        <v-icon>fa fa-undo</v-icon>
                      </v-btn>
                    </b-button-group>
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-3">Selected Member Image</h6>
                  <cropper
                    ref="cropper"
                    :src="SelectedImage.ImageUrl"
                    :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'preview',
                  }"
                    :stencil-size="{
                    width: 300,
                    height: 300,
                  }"
                    image-restriction="stencil"
                  />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Facebook
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field v-model="Facebook" :rules="FacebookRules" required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Twitter
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field v-model="Twitter" :rules="TwitterRules" required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Instagram
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field v-model="Instagram" :rules="InstagramRules" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="getCroppedImage"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Save</v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-5 mr-2 white--text"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      ZoneCodeRules: [v => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [v => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      MemberNameRules: [v => !!v || "Member Name is required"],
      MemberName: "",

      EmailIdRules: [],
      EmailId: "",

      MobileNoRules: [],
      MobileNo: "",

      Address: "",

      CityNameRules: [],
      CityName: "",

      FacebookRules: [],
      Facebook: "",

      TwitterRules: [],
      Twitter: "",

      InstagramRules: [],
      Instagram: "",

      SelectedImage: {
        ImageType: "",
        ImageUrl: null
      },
      uploadedImages: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function() {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "wm_members",
        Action: "create"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/wm/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/wm/lom/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          ZoneCode: ZoneCode,
          AllLom: 0
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message
        };
      }
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.uploadedImages;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null
          };
          this.uploadedImages = {};
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      console.log("getCroppedImage called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var ZoneCode = this.ZoneCode;
      var LomCode = this.LomCode;
      var MemberName = this.MemberName;

      console.log(
        "LomCode=" +
          LomCode +
          ", ZoneCode=" +
          ZoneCode +
          ", MemberName=" +
          MemberName
      );

      if (validate1 && ZoneCode != "" && LomCode != "" && MemberName != "") {
        var MobileNo =
          this.MobileNo == (null || undefined) ? "" : this.MobileNo;
        var EmailId = this.EmailId == (null || undefined) ? "" : this.EmailId;
        var Address = this.Address == (null || undefined) ? "" : this.Address;
        var CityName =
          this.CityName == (null || undefined) ? "" : this.CityName;
        var Facebook =
          this.Facebook == (null || undefined) ? "" : this.Facebook;
        var Twitter = this.Twitter == (null || undefined) ? "" : this.Twitter;
        var Instagram =
          this.Instagram == (null || undefined) ? "" : this.Instagram;

        let upload = new FormData();
        upload.append("ZoneCode", ZoneCode);
        upload.append("LomCode", LomCode);
        upload.append("MemberName", MemberName);
        upload.append("MobileNo", MobileNo);
        upload.append("EmailId", EmailId);
        upload.append("Address", Address);
        upload.append("CityName", CityName);
        upload.append("Facebook", Facebook);
        upload.append("Twitter", Twitter);
        upload.append("Instagram", Instagram);

        var file = this.uploadedImages;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob(blob => {
            upload.append("ProfilePicture", blob, FileName);
            this.submitForm(upload);
          }, FileType);
        } else {
          if (FileName) {
            message = "Kindly select image. ";
            this.sweetAlert("error", message, false);
          } else {
            upload.append("ProfilePicture", null);
            this.submitForm(upload);
          }
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      this.progessStart = 1;
      this.SubmitFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/wm/team/members/store";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;
          thisIns.progessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.closePrompt();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.progessStart = 0;
          thisIns.SubmitFlag = false;
        });
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>